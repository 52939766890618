import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history", // remove to get # instead of normal urls

  routes: [
    { path: "*", redirect: "/" },

    {
      path: "/",
      name: "Login",
      alias: ["/login"],
      component: () => import("@/components/layouts/LoginLayout.vue"),
      meta: {
        hideForAuth: true,
      },
    },
    {
      path: "/:path(.*)",
      component: () => import("@/components/layouts/AuthorisedLayout.vue"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          component: () => import("@/views/DashboardView.vue"),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/chats",
          name: "Chats",
          component: () => import("@/views/ChatsView.vue"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ":chatId",
              component: () => import("../components/chats/ChatsDetails.vue"),
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  redirect: { name: "Chat - Charges" },
                },
                {
                  path: "bundles",
                  name: "Chat - Bundles",
                  component: () =>
                    import("../components/chats/ChatsDetailsBundles.vue"),
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "charges",
                  name: "Chat - Charges",
                  component: () =>
                    import("../components/chats/ChatsDetailsCharges.vue"),
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "profiles",
                  name: "Chat - Profiles",
                  component: () =>
                    import("../components/chats/ChatsDetailsProfiles.vue"),
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "keywords",
                  name: "Chat - Keywords",
                  component: () =>
                    import("../components/chats/ChatsDetailsKeywords.vue"),
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "settings",
                  name: "Chat - Settings",
                  component: () =>
                    import("../components/chats/ChatsDetailsSettings.vue"),
                  meta: {
                    requiresAuth: true,
                  },
                },
              ],
            },
          ],
        },

        {
          path: "/operators",
          name: "Operators",
          component: () => import("@/views/OperatorsView.vue"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ":operatorId",
              name: "Operator - details",
              component: () =>
                import("../components/operators/OperatorsIndividual.vue"),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "/stats",
          component: () => import("@/views/StatsView.vue"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              redirect: { name: "Stats - Transactions" },
            },
            {
              path: "transactions",
              name: "Stats - Transactions",
              component: () =>
                import("../components/stats/StatsTransactions.vue"),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "/profiles",
          name: "Profiles",
          component: () => import("@/views/ProfilesView.vue"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ":profileId",
              component: () =>
                import("../components/profiles/ProfilesIndividual.vue"),
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  redirect: { name: "Profile - Conversations" },
                },
                {
                  path: "conversations",
                  name: "Profile - Conversations",
                  component: () =>
                    import(
                      "../components/profiles/ProfilesIndividualConversationList.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "transactions",
                  name: "Profile - Transactions",
                  component: () =>
                    import(
                      "../components/profiles/ProfilesIndividualTransactions.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "commands",
                  name: "Profile - Commands",
                  component: () =>
                    import(
                      "../components/profiles/ProfilesIndividualCommands.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                  },
                },
              ],
            },
          ],
        },

        {
          path: "/queues",
          name: "Queues",
          component: () => import("@/views/QueuesView.vue"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "available-conversations",
              name: "Queues - Available Conversations",
              component: () =>
                import("../components/queues/QueuesMessageQueue.vue"),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "/alerts",
          name: "Alerts",
          component: () => import("@/views/AlertsView.vue"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ":siteId",
              name: "Alert",
              component: () =>
                import("../components/alerts/AlertsIndividual.vue"),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },

        {
          path: "/templates",
          name: "Templates",
          component: () => import("@/views/TemplatesView.vue"),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/numbers",
          name: "Numbers",
          component: () => import("@/views/NumbersView.vue"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ":numberSetId",
              name: "Number",
              component: () =>
                import("../components/numbers/NumbersDetail.vue"),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const segments = to.fullPath.split("/");
  if (segments.length >= 2 && segments[1] !== "") {
    document.title = `Prsoc - ${
      segments[1].charAt(0).toUpperCase() + segments[1].slice(1)
    }`;
  } else {
    document.title = "Prsoc - Login";
  }

  if (to.path.endsWith("/") && to.path !== "/") {
    const pathWithoutTrailingSlash = to.path.slice(0, -1);
    next({ path: pathWithoutTrailingSlash });
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires session cookie, check if logged in
    // if not, redirect to login page.
    if (
      window.$cookies.get("APN_PRSOC_token") == null ||
      window.$cookies.get("APN_PRSOC_refresh") == null
    ) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (window.$cookies.isKey("APN_PRSOC_token")) {
      next({ path: "/dashboard" });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
