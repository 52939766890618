var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{ref:"drawer",staticClass:"rounded-lg",class:{ 'shadow-lg': _vm.drawerComputedWidth !== '115px' },staticStyle:{"z-index":"6"},attrs:{"absolute":"","expand-on-hover":_vm.expandOnHover,"mini-variant":"","mini-variant-width":"115px","stateless":"","permanent":"","floating":"","color":"consoleWhite"}},[_c('v-list',{staticClass:"d-flex flex-column fill-height pr-0",attrs:{"rounded":""}},[_c('v-list-item',{staticClass:"mx-auto",staticStyle:{"flex":"1"}},[_c('v-list-item-avatar',{staticClass:"mx-auto"},[_c('v-img',{attrs:{"src":_vm.logo,"alt":"Premium rates services operator's console logo that resembles a guitar mediator"}})],1)],1),(_vm.$route.name === 'Queues - Available Conversations')?_c('v-list-item',{staticClass:"align-start",staticStyle:{"flex":"8"},attrs:{"rounded":""}},[_c('v-badge',{attrs:{"content":_vm.availableConversations.length,"color":_vm.availableConversations.length !== 0 ? 'consoleAmber' : 'transparent'}},[_c('v-icon',{attrs:{"color":"consolePrimary"}},[_vm._v("fas fa-comment")])],1)],1):_c('v-list-item-group',{staticClass:"px-2",staticStyle:{"flex":"8"}},_vm._l((_vm.filteredNavigationOptions),function(item){return _c('v-list-item',{key:item.id,staticClass:"text-decoration-none",attrs:{"to":item.to,"color":"consoleBackground","active-class":"consoleBackground"}},[_c('v-list-item-icon',[_c('v-badge',{attrs:{"content":item.id === 2 &&
              !_vm.$route.path.startsWith('/queues') &&
              _vm.availableConversations.length !== 0
                ? _vm.availableConversations.length
                : null,"color":item.id === 2 &&
              !_vm.$route.path.startsWith('/queues') &&
              _vm.availableConversations.length !== 0
                ? 'consoleAmber'
                : 'transparent'}},[_c('v-icon',{attrs:{"color":"consoleText"}},[_vm._v(_vm._s(item.icon)+" ")])],1)],1),_c('v-list-item-title',{staticClass:"consoleText--text font-weight-bold text-center"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1),_c('v-list-item',{staticClass:"justify-center",staticStyle:{"flex":"1"}},[_c('v-tooltip',{attrs:{"right":"","color":"consolePrimary","content-class":"rounded-lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","plain":"","color":"transparent"},on:{"click":function($event){return _vm.openCloseTheSoundOverlay(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-auto",attrs:{"color":"consoleText"}},[_vm._v(" "+_vm._s(_vm.isSoundEnabled ? "fa-solid fa-volume-high" : "fa-solid fa-volume-xmark")+" ")])],1)]}}])},[_c('span',[_vm._v(" Sound is "+_vm._s(_vm.isSoundEnabled ? `set to ${_vm.soundVolume}%` : "off")+" ")])])],1),_c('v-list-item',{staticClass:"justify-center",staticStyle:{"flex":"1"}},[_c('v-tooltip',{attrs:{"right":"","color":"consolePrimary","content-class":"rounded-lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","plain":"","color":"transparent"},on:{"click":_vm.toggleTheme}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-auto",attrs:{"color":"consoleText"}},[_vm._v(" "+_vm._s(_vm.$vuetify.theme.dark ? "fa-solid fa-moon" : "fa-solid fa-sun")+" ")])],1)]}}])},[_c('span',[_vm._v(" Switch to "+_vm._s(_vm.$vuetify.theme.dark ? "light" : "dark")+" mode ")])])],1),_c('v-list-item',{staticClass:"justify-center",staticStyle:{"flex":"1"}},[_c('v-list-item-action',{staticClass:"my-0 align-center mx-auto"},[_c('v-switch',{style:(_vm.$route.name === 'Queues - Available Conversations'
              ? 'visibility: hidden'
              : ''),attrs:{"value":_vm.expandOnHover,"color":"consoleText"},on:{"change":_vm.toggleExpandOnHover}}),_c('v-chip',{staticClass:"text-caption font-weight-light transparent consoleText--text"},[_vm._v(" "+_vm._s(_vm.getAppVersion)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }