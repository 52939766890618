export default {
  versionUpdates: [
    {
      name: "1.7.2 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'>Updated <b>internal handling</b> of conversation notes. " +
        "",
      start: new Date("2024-07-30"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.7.1 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'>Enhanced <b>login mechanism</b> compatibility with the backend system. " +
        "",
      start: new Date("2024-07-01"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.7.0 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'><b>AV</b> badge added to <b>Adult Vefirfied</b> customer profiles within <b>Individual Profile Page</b>" +
        "<li class='mb-1'><b>Gender</b> and <b>Orientation</b> added upon <b>creation/editing</b> of monitor profiles " +
        "<li class='mb-1'>The above includes corresponding <b>color coding</b> and <b>tooltips on hover</b> within profile list" +
        "<li class='mb-1'><b>Dynamic phrase handlers</b> introduced within create/edit <b>SMS Template Overlay</b>" +
        "<li class='mb-1'><b>Free Credits</b> transaction type added under <b>Profiles Transactions</b>" +
        "<li class='mb-1'><b>Profile Commands</b> and <b>Profiile Transactions</b> now have ability to <b>sort the list</b> in ascending/descending order <b>by date</b>" +
        "<li class='mb-1'>In addition to the above, each command now show pertinent <b>state (failed or processed)</b>" +
        "<li class='mb-1'><b>Commands</b> section renamed to <b>Keywords</b> within Chat Details" +
        "<li class='mb-1'><b>Chats Settings</b> now have controls for enabling/disabling adult verification for the chat in question" +
        "<li class='mb-1'>Once the above is <b>enabled</b>, an option to <b>set AV Icentive</b> will appear under <b>Charges Tab</b> for the chat" +
        "<li class='mb-1'><b>Sound notification</b> introduced for unseen messages whilst you're navigated away from the tab. You can even <b>set the volume!</b>" +
        "<li class='mb-1'>You can now choose to us a <b>keyword</b> as a <b>shortcode</b>. For <b>the UK</b> chats only at the moment" +
        "<li class='mb-1'><b>Bundle entry fields</b> now have validation. Only whole number for <b>amount of credits</b> and whole number or decimal with two spaces (e.g. 1.99) for <b>price</b>" +
        "<li class='mb-1'>Text readibility improved on <b>Dark Theme</b>" +
        "",
      start: new Date("2024-04-09"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.6.0 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'>Design changes to <b>Alerts</b> page" +
        "<li class='mb-1'><b>Notification</b> pop-up now split into Title and Text Content" +
        "<li class='mb-1'>Improved <b>Error Handling</b>" +
        "<li class='mb-1'><b>Nested routes</b> added app-wide" +
        "<li class='mb-1'>New <b>animations</b> introduced" +
        "<li class='mb-1'>Various code base changes aimed to <b>optimise</b> the app" +
        "<li class='mb-1'>Additional functionality for adding and editing  <b>notes</b> within available conversations" +
        "",
      start: new Date("2024-01-18"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.5.1 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'>Optimised <b>Alerts</b> page" +
        "<li class='mb-1'>Added new <b>Payment Type Idenitfiers</b> within <b>Stats</b> page and <b>Profile Detail View</b> page" +
        "<li class='mb-1'>Increased availble profile selection from <b>10 to 25 </b> within <b>Chat Profile Selection</b>" +
        "",
      start: new Date("2023-10-27"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.5.0 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'>Optimised <b>Navigation</b> and <b>Header</b> layout for all screen sizes" +
        "<li class='mb-1'>Added ability to <b>Enable</b> operators, optimised <b>Operators</b> page" +
        "<li class='mb-1'>Added ability to see <strong>Conversation amount indicator</strong> for all user groups" +
        "",
      start: new Date("2023-06-19"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.4.0 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'><b>Magic Numbers</b> added with ability to assign to <b>profiles</b>" +
        "<li class='mb-1'><b>Profile List</b> optimised and facelifted" +
        "<li class='mb-1'>The ability to have <b>SMS Conversations</b> has been added" +
        "<li class='mb-1'><b>Profile Detail View Information</b> area facelifted" +
        "<li class='mb-1'>Navigation <b>back button</b> sticking after queue backout resolved",
      start: new Date("2022-12-15"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.3.0 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'><b>Notifications</b> added, alerts, suggestions will appear on bottom right" +
        "<li class='mb-1'><b>Profile Detail View</b> updated, w/ <b>Transactions</b>,<b>Conversations</b>,<b>Commands</b>" +
        "<li class='mb-1'><b>Conversation Queue</b> optimised and facelifted",
      start: new Date("2022-08-10"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.2.0 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'><b>SMS Commands/Keywords</b> assignation added, within <b>Chats Settings</b>" +
        "<li class='mb-1'>Dynamic <b>SMS Template</b> assignation, customasation added" +
        "<li class='mb-1'><b>Chat Settings</b> added" +
        "<li class='mb-1'><b> Profile Region identifier </b> added within the queue" +
        "<li class='mb-1'><b>Top navigation</b> added, options may differ depending on your role",
      start: new Date("2022-05-19"),
      color: "consoleBackgroundSecondary",
    },
    {
      name: "1.1.2 Update",
      pinned: false,
      content:
        "" +
        "<li class='mb-1'><b>Message Board</b> list concept implemented" +
        "<li class='mb-1'>Ability to go through <b>Calendar</b> months added" +
        "<li class='mb-1'><b>Image Uploader</b> crop area increased" +
        "<li class='mb-1'>Issue with pages not showing in <b>Safari</b> browser resolved",
      start: new Date("2021-10-14"),
      color: "consoleBackgroundSecondary",
    },
  ],
};
