export const totalConversations = (state) => {
  return state.totalConversations;
};
export const seenConversationIds = (state) => {
  return state.seenConversationIds;
};

export const isSoundEnabled = (state) => {
  return state.isSoundEnabled;
};
export const soundVolume = (state) => {
  return state.soundVolume;
};

export const availableConversations = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  return state.totalConversations.filter((conversation) => {
    return (
      conversation.assignedUserId === null ||
      conversation.assignedUserId ===
        rootGetters["navigation/getAuthorisedUser"].id
    );
  });
};
export const customerNotes = (state) => {
  return state.customerNotes;
};

export const nonCustomerNotes = (state) => {
  return state.nonCustomerNotes;
};

export const notesCategories = (state) => {
  return state.notesCategories;
};

export const overlayMessage = (state) => {
  return state.overlayMessage;
};
