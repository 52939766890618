export const dashboardMessages = (state) => {
  return state.versionUpdates.map((update) => {
    const { color, ...updateWithoutColor } = update;
    return updateWithoutColor;
  });
};

export const calendarSystemEvents = (state) => {
  return state.versionUpdates.map((update) => {
    return {
      name: update.name,
      start: update.start,
      color: update.color,
    };
  });
};
