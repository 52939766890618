import { getAllOperatorList } from "@/api/requestService";

export const fetchAllOperators = async (context) => {
  try {
    const response = await getAllOperatorList();
    context.commit("fetchAllOperators", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load operator list",
        error: error.response?.status,
      },
      {
        root: true,
      }
    );
  }
};
