<template>
  <v-overlay
    id="main-template-overlay"
    absolute
    color="consoleBackground"
    z-index="99"
  >
    <v-card
      class="rounded-lg consoleWhite d-flex flex-column"
      :light="!$vuetify.theme.dark"
      width="50vw"
      height="45vh"
      v-click-outside="closeVolumeOverlay"
    >
      <v-btn
        class="sound__close"
        @click="closeVolumeOverlay"
        icon
        color="consolePrimary"
        absolute
        top
        right
        style="z-index: 1"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
      <v-card-title
        class="sound__header consoleText--text text-h6 justify-center pt-6"
      >
        Notification Volume
      </v-card-title>
      <v-card-actions
        class="sound__content d-flex flex-column justify-center align-center fill-height"
      >
        <div
          style="width: 100%"
          class="d-flex flex-column justify-center align-center"
        >
          <v-slider
            v-model="$store.state.queues.soundVolume"
            @mouseup="handleMouseUp"
            hide-details
            thumb-label
            color="consolePrimary"
            track-color="consoleGrey"
            thumb-color="consoleBackgroundSecondary"
            style="width: 80%"
          >
            <template #thumb-label> {{ `${soundVolume}%` }} </template>
            <template #prepend>
              <v-btn
                @click="toggleIsSoundEnabled"
                fab
                class="shadow-none"
                color="transparent"
              >
                <v-icon
                  :color="soundVolume === 0 ? 'consoleRed' : 'consolePrimary'"
                >
                  {{ sliderIcon }}
                </v-icon>
              </v-btn>
            </template>
          </v-slider>
        </div>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import notificationSound from "@/assets/audio/notificationSound.wav";
import { gsap } from "gsap";

export default {
  computed: {
    ...mapGetters("queues", ["isSoundEnabled", "soundVolume"]),
    sliderIcon() {
      return this.soundVolume === 0
        ? "fa-solid fa-volume-xmark"
        : this.soundVolume < 33
        ? "fa-solid fa-volume-off"
        : this.soundVolume < 66
        ? "fa-solid fa-volume-low"
        : "fa-solid fa-volume-high";
    },
  },
  methods: {
    ...mapMutations("navigation", ["openCloseTheSoundOverlay"]),
    ...mapMutations("queues", ["toggleIsSoundEnabled", "setIsSoundEnabled"]),
    handleMouseUp() {
      if (this.soundVolume === 0) {
        this.setIsSoundEnabled(false);
      } else {
        this.setIsSoundEnabled(true);
        const audio = new Audio(notificationSound);
        audio.volume = this.soundVolume / 100;
        audio.play();
        localStorage.setItem("soundVolume", this.soundVolume);
      }
    },
    async closeVolumeOverlay() {
      await this.theSoundOverlayAnimationTimeline.reverse();
      this.openCloseTheSoundOverlay(false);
    },
    handleEscapeKey(event) {
      if (event.key === "Escape" || event.keyCode === 27) {
        this.closeVolumeOverlay();
      }
    },
    theSoundOverlayAnimation() {
      this.theSoundOverlayAnimationTimeline = gsap.timeline({
        onComplete: () => {
          this.theSoundOverlayAnimationTimeline.kill();
        },
      });
      this.theSoundOverlayAnimationTimeline
        .fromTo(
          ".sound__close",
          { opacity: 0 },
          { opacity: 1, duration: 0.3, ease: "power1" },
          0
        )
        .fromTo(
          ".sound__header",
          { opacity: 0, y: "-10%" },
          { opacity: 1, y: 0, duration: 0.3, ease: "power1" },
          0
        )
        .fromTo(
          ".sound__content",
          { opacity: 0, y: "10%" },
          { opacity: 1, y: 0, duration: 0.3, ease: "power1" },
          0
        );
    },
  },
  mounted() {
    this.theSoundOverlayAnimation();
    document.addEventListener("keydown", this.handleEscapeKey);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscapeKey);
  },
};
</script>
<style scoped>
#main-template-overlay {
  backdrop-filter: blur(10px);
}
>>> .v-input {
  align-items: center;
}
</style>
