import { getTemplates } from "@/api/requestService";
export const fetchAllTemplates = async (context) => {
  try {
    const response = await getTemplates();
    context.commit("fetchAllTemplates", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      { text: "Oops! Couldn't load templates", error: error.response?.status },
      { root: true }
    );
  }
};
