<template>
  <v-navigation-drawer
    ref="drawer"
    absolute
    :expand-on-hover="expandOnHover"
    mini-variant
    mini-variant-width="115px"
    stateless
    permanent
    floating
    style="z-index: 6"
    color="consoleWhite"
    class="rounded-lg"
    :class="{ 'shadow-lg': drawerComputedWidth !== '115px' }"
  >
    <v-list rounded class="d-flex flex-column fill-height pr-0">
      <v-list-item style="flex: 1" class="mx-auto">
        <v-list-item-avatar class="mx-auto">
          <v-img
            :src="logo"
            alt="Premium rates services operator's console logo that resembles a guitar mediator"
          />
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item
        style="flex: 8"
        v-if="$route.name === 'Queues - Available Conversations'"
        rounded
        class="align-start"
      >
        <v-badge
          :content="availableConversations.length"
          :color="
            availableConversations.length !== 0 ? 'consoleAmber' : 'transparent'
          "
        >
          <v-icon color="consolePrimary">fas fa-comment</v-icon>
        </v-badge>
      </v-list-item>
      <v-list-item-group v-else style="flex: 8" class="px-2">
        <v-list-item
          v-for="item in filteredNavigationOptions"
          :key="item.id"
          :to="item.to"
          class="text-decoration-none"
          color="consoleBackground"
          active-class="consoleBackground"
        >
          <v-list-item-icon>
            <v-badge
              :content="
                item.id === 2 &&
                !$route.path.startsWith('/queues') &&
                availableConversations.length !== 0
                  ? availableConversations.length
                  : null
              "
              :color="
                item.id === 2 &&
                !$route.path.startsWith('/queues') &&
                availableConversations.length !== 0
                  ? 'consoleAmber'
                  : 'transparent'
              "
            >
              <v-icon color="consoleText">{{ item.icon }} </v-icon>
            </v-badge>
          </v-list-item-icon>

          <v-list-item-title
            class="consoleText--text font-weight-bold text-center"
          >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-list-item style="flex: 1" class="justify-center">
        <v-tooltip right color="consolePrimary" content-class="rounded-lg">
          <template v-show="false" v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="openCloseTheSoundOverlay(true)"
              fab
              depressed
              plain
              color="transparent"
            >
              <v-icon color="consoleText" class="mx-auto">
                {{
                  isSoundEnabled
                    ? "fa-solid fa-volume-high"
                    : "fa-solid fa-volume-xmark"
                }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            Sound is {{ isSoundEnabled ? `set to ${soundVolume}%` : "off" }}
          </span>
        </v-tooltip>
      </v-list-item>

      <v-list-item style="flex: 1" class="justify-center">
        <v-tooltip right color="consolePrimary" content-class="rounded-lg">
          <template v-show="false" v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="toggleTheme"
              fab
              depressed
              plain
              color="transparent"
            >
              <v-icon color="consoleText" class="mx-auto">
                {{
                  $vuetify.theme.dark ? "fa-solid fa-moon" : "fa-solid fa-sun"
                }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            Switch to {{ $vuetify.theme.dark ? "light" : "dark" }} mode
          </span>
        </v-tooltip>
      </v-list-item>
      <v-list-item style="flex: 1" class="justify-center">
        <v-list-item-action class="my-0 align-center mx-auto">
          <v-switch
            :style="
              $route.name === 'Queues - Available Conversations'
                ? 'visibility: hidden'
                : ''
            "
            :value="expandOnHover"
            @change="toggleExpandOnHover"
            color="consoleText"
          />
          <v-chip
            class="text-caption font-weight-light transparent consoleText--text"
          >
            {{ getAppVersion }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      logo: require("@/assets/images/logos/prsocLogo.png"),
      drawerAvailable: false,
    };
  },
  computed: {
    ...mapGetters("navigation", [
      "filteredNavigationOptions",
      "getAppVersion",
      "expandOnHover",
    ]),
    ...mapGetters("queues", [
      "availableConversations",
      "isSoundEnabled",
      "soundVolume",
    ]),
    drawerComputedWidth() {
      if (this.drawerAvailable) {
        return this.$refs.drawer.computedWidth;
      }
    },
  },
  methods: {
    ...mapMutations("navigation", [
      "toggleExpandOnHover",
      "openCloseTheSoundOverlay",
    ]),
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("darkTheme", this.$vuetify.theme.dark.toString());
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.drawer) {
        this.drawerAvailable = true;
      }
    });
  },
};
</script>
