export default {
  overlayMessage: "",
  totalConversations: [],
  seenConversationIds: [],
  isSoundEnabled: true,
  soundVolume: localStorage.getItem("soundVolume")
    ? localStorage.getItem("soundVolume")
    : 80,
  customerNotes: [],
  nonCustomerNotes: [],
  notesCategories: [
    {
      title: "Description",
      idNumber: "0",
      color: "#4ea7f5",
      icon: "fas fa-info",
    },
    {
      title: "Career",
      idNumber: "1",
      color: "#001c96",
      icon: "fas fa-user-tie",
    },
    {
      title: "Relationships",
      idNumber: "2",
      color: "#ed66ae",
      icon: "fas fa-heart",
    },
    {
      title: "Hobbies",
      idNumber: "3",
      color: "#089606",
      icon: "fas fa-hiking",
    },
    {
      title: "Living Situation",
      idNumber: "4",
      color: "#f2de24",
      icon: "fas fa-bed",
    },
    {
      title: "Location",
      idNumber: "5",
      color: "#f22424",
      icon: "fas fa-map-marker-alt",
    },
    {
      title: "Sexual Desires",
      idNumber: "6",
      color: "#d40000",
      icon: "fas fa-fire",
    },
    {
      title: "Future Plans",
      idNumber: "7",
      color: "#bb24f2",
      icon: "fas fa-calendar-day",
    },
    {
      title: "Other",
      idNumber: "8",
      color: "#787878",
      icon: "fas fa-ellipsis-h",
    },
  ],
};
