export const rangeOptions = (state) => {
  return state.rangeOptions;
};

export const regionId = (state) => {
  return state.regionId;
};

export const rangeId = (state) => {
  return state.rangeId;
};

export const revenueData = (state) => {
  return state.revenueData;
};

export const transactions = (state) => {
  return state.transactions;
};

export const messageCounts = (state) => {
  return state.messageCounts;
};

export const messageCountType = (state) => {
  return state.messageCountType;
};
