export const filteredHeaderOptions = (state) => {
  return state.headerOptions.filter((option) =>
    option.roles.includes(state.authorisedUserRole)
  );
};

export const filteredNavigationOptions = (state) => {
  return state.navigationOptions.filter((option) =>
    option.roles.includes(state.authorisedUserRole)
  );
};

export const getAppVersion = (state) => {
  return state.appVersion;
};

export const getAuthorisedUser = (state) => {
  return state.authorisedUser;
};

export const getAuthorisedUserRole = (state) => {
  return state.authorisedUserRole;
};

export const expandOnHover = (state) => {
  return state.expandOnHover;
};
export const showTheSoundOverlay = (state) => {
  return state.showTheSoundOverlay;
};
