export const errorSnackbarText = (state) => {
  return state.errorSnackbarText;
};

export const errorSnackbarTimeout = (state) => {
  return state.errorSnackbarTimeout;
};

export const alert = (state) => {
  return state.alert;
};
