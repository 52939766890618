<template>
  <v-row class="the-header align-center ma-0">
    <v-col
      :cols="$route.path === '/profiles' ? 2 : 10"
      class="consoleText--text text-h5 font-weight-bold d-flex justify-start align-center"
    >
      <v-btn
        class="text-decoration-none mr-2"
        small
        icon
        color="transparent"
        @click="backButtonRoute"
        :disabled="!showBackButton"
      >
        <v-scroll-x-reverse-transition>
          <v-icon v-if="showBackButton" color="consolePrimary">
            fas fa-angle-left
          </v-icon>
        </v-scroll-x-reverse-transition>
      </v-btn>
      <v-slide-y-transition hide-on-leave>
        <p class="mb-0 text-capitalize" :key="$route.fullPath.split('/')[1]">
          {{ $route.fullPath.split("/")[1] }}
        </p>
      </v-slide-y-transition>
    </v-col>
    <v-col
      v-if="$route.path === '/profiles'"
      cols="8"
      class="the-header__profile-search d-flex justify-center"
    >
      <TheHeaderProfileSearch />
    </v-col>
    <v-col cols="2" class="the-header__my-account d-flex justify-end pr-4">
      <TheHeaderMyAccount />
    </v-col>
  </v-row>
</template>

<script>
import TheHeaderProfileSearch from "@/components/global/TheHeaderProfileSearch.vue";
import { mapActions } from "vuex";
export default {
  components: {
    TheHeaderProfileSearch,
    TheHeaderMyAccount: () =>
      import(
        /* webpackPrefetch: true */ "@/components/global/TheHeaderMyAccount.vue"
      ),
  },

  data() {
    return {
      knownPaths: ["/profiles", "/chats", "/queues"],
    };
  },
  computed: {
    showBackButton() {
      return this.knownPaths.some(
        (path) => this.$route.path.startsWith(path) && this.$route.path !== path
      );
    },
  },
  methods: {
    ...mapActions("queues", ["changeOverlayMessage"]),
    async backButtonRoute() {
      if (this.$route.path.startsWith("/queues")) {
        await this.changeOverlayMessage("leave");
      } else {
        const routePath = this.knownPaths.find((path) =>
          this.$route.path.startsWith(path)
        );
        await this.$router.push(routePath);
      }
    },
  },
};
</script>
