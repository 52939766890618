export const setProfilesByPage = (state, payload) => {
  state.profilesByPage = payload.content;
  state.totalPages = payload.totalPages;
};

export const setCurrentPage = (state, payload) => {
  state.currentPage = payload;
};

export const setSearchMode = (state, payload) => {
  state.searchMode = payload;
};

export const setSearchParams = (state, payload) => {
  state.searchParams = payload;
};
