export const allNumberSets = (state) => {
  const allNumberSetsCopy = [...state.allNumberSets];

  return allNumberSetsCopy.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const getSetById = (state) => (id) => {
  return state.allNumberSets.find((set) => set.id === parseInt(id));
};

export const targetNumbers = (state) => {
  return state.targetNumbers;
};
