<template>
  <v-snackbar
    :value="errorSnackbarText"
    top
    elevation="5"
    rounded="pill"
    color="consoleRed"
  >
    <p class="text-center ma-0 consoleTextWhite--text">
      {{ errorSnackbarText }}
    </p>
  </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("toast", ["errorSnackbarText"]),
  },
};
</script>
