<template id="template">
  <v-app style="min-width: 768px; min-height: 100vh">
    <TheNotification style="position: absolute" />
    <router-view v-slot="slotProps">
      <component :is="slotProps.Component" />
    </router-view>
  </v-app>
</template>

<script>
import { getActiveAlert } from "@/api/requestService";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters("toast", ["alert"]),
    consolePrimaryColor() {
      return this.$vuetify.theme.themes.light.consolePrimary;
    },
  },
  methods: {
    ...mapActions("toast", ["setAlert", "enableErrorSnackbar"]),
    ...mapMutations("navigation", ["openCloseTheSoundOverlay"]),
    checkBrowserType() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isChrome = /chrome/i.test(userAgent);
      if (!isChrome) {
        this.$vs.notification({
          icon: `<v-icon class="fa-brands fa-chrome"/>`,
          color: `${this.consolePrimaryColor}`,
          duration: "none",
          text: "For the best possible experience please switch to <b>Google Chrome</b>",
        });
      }
    },
    async checkAlertState() {
      try {
        const response = await getActiveAlert();
        const isSameAlert = response.data?.expiry === this.alert.expiry;
        if (isSameAlert) {
          return;
        }
        await this.setAlert(response.data);
        if (this.alert.active) {
          this.$vs.notification({
            icon: `<v-icon class="${this.alert.alertIcon}"/>`,
            color: this.alert.alertColour,
            duration: "none",
            title: this.alert.alertContentPrimary,
            text: this.alert.alertContentSecondary,
          });
        }
      } catch (error) {
        await this.enableErrorSnackbar({
          text: "Error fetching active alert",
          error: error.response?.status,
        });
      }
    },
  },
  created() {
    this.checkBrowserType();
    this.checkAlertState();
    setInterval(this.checkAlertState, 10000);
    if (!this.$route.name) {
      this.openCloseTheSoundOverlay(true);
    }
  },
};
</script>
