import { getTotalChats, getChatChargesById } from "@/api/requestService";
export const fetchAllChats = async (context) => {
  try {
    const response = await getTotalChats();
    context.commit("setAllChats", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load chats information",
        error: error.response?.status,
      },
      { root: true }
    );
  }
};
export const fetchTargetChatCharges = async (context, id) => {
  try {
    const response = await getChatChargesById(id);
    context.commit("setTargetChatCharges", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load chat charges",
        error: error.response?.status,
      },
      { root: true }
    );
  }
};
export const setTargetChat = async (context, id) => {
  const targetChat = context.getters.allChats.find(
    (chat) => chat.id === parseInt(id)
  );
  context.commit("setTargetChat", targetChat);
};
