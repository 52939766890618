import { getPaginationProfileSearchList } from "@/api/requestService";
export const fetchProfilesByPage = async (context, page) => {
  try {
    const response = await getPaginationProfileSearchList(page);
    context.commit("setProfilesByPage", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      { text: "Oops! Couldn't load profiles", error: error.response?.status },
      {
        root: true,
      }
    );
  }
};

export const setCurrentPage = (context, page) => {
  context.commit("setCurrentPage", page);
};

export const setSearchResults = async (context, { paginationPage, params }) => {
  try {
    const response = await getPaginationProfileSearchList(
      paginationPage,
      params
    );
    context.commit("setProfilesByPage", response.data);
    context.commit("setCurrentPage", paginationPage + 1);
    context.commit("setSearchMode", true);
    context.commit("setSearchParams", params);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load search results",
        error: error.response?.status,
      },
      {
        root: true,
      }
    );
  }
};
