import {
  getSignedInUserInfo,
  getUserIdFromUsername,
} from "@/api/requestService";

export const setAuthorisedUser = async (context) => {
  try {
    const token = window.$cookies.get("APN_PRSOC_token");
    const base64Url = token.split(".")[1];
    const decodedData = JSON.parse(atob(base64Url));

    const userId = await getUserIdFromUsername(decodedData.user_name);
    const authorisedUser = await getSignedInUserInfo(userId.data);
    context.commit("setAuthorisedUser", authorisedUser.data);
    context.commit("setAuthorisedUserRole", decodedData.authorities[0]);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Could not identify the operator",
        error: error.response?.status,
      },
      {
        root: true,
      }
    );
  }
};
