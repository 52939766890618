import router from "@/router/router";
export const enableErrorSnackbar = async (context, error) => {
  if (error.error === 401 || error === 503) {
    window.location.reload();
  } else if (error.error === 403) {
    await router.push("/dashboard");
    context.commit(
      "enableErrorSnackbar",
      "Insufficient rights to access this page"
    );
    setTimeout(
      () => context.commit("enableErrorSnackbar", ""),
      context.getters.errorSnackbarTimeout
    );
  } else {
    context.commit("enableErrorSnackbar", error.text);
    setTimeout(
      () => context.commit("enableErrorSnackbar", ""),
      context.getters.errorSnackbarTimeout
    );
  }
};
export const setAlert = async (context, alert) => {
  context.commit("setAlert", alert);
};
