export const setTotalConversations = (state, payload) => {
  state.totalConversations = payload;
};

export const setCustomerNotes = (state, payload) => {
  state.customerNotes = payload;
};
export const setNonCustomerNotes = (state, payload) => {
  state.nonCustomerNotes = payload;
};

export const changeOverlayMessage = (state, payload) => {
  state.overlayMessage = payload;
};

export const addConversationIdToSeen = (state, payload) => {
  state.seenConversationIds.push(payload);
};

export const removeConversationIdFromSeen = (state, payload) => {
  const index = state.seenConversationIds.indexOf(payload);
  if (index !== -1) {
    state.seenConversationIds.splice(index, 1);
  }
};

export const toggleIsSoundEnabled = (state) => {
  state.isSoundEnabled = !state.isSoundEnabled;
  if (!state.isSoundEnabled) {
    state.soundVolume = 0;
    localStorage.setItem("isSoundEnabled", "false");
  } else {
    state.soundVolume = localStorage.getItem("soundVolume")
      ? parseInt(localStorage.getItem("soundVolume"))
      : 80;
    localStorage.setItem("isSoundEnabled", "true");
  }
};

export const setIsSoundEnabled = (state, payload) => {
  state.isSoundEnabled = payload;
  localStorage.setItem("isSoundEnabled", payload);
  if (!state.isSoundEnabled) {
    state.soundVolume = 0;
  }
};
