export const setAuthorisedUser = (state, payload) => {
  state.authorisedUser = payload;
};

export const setAuthorisedUserRole = (state, payload) => {
  state.authorisedUserRole = payload;
};

export const toggleExpandOnHover = (state) => {
  state.expandOnHover = !state.expandOnHover;
};

export const disableExpandOnHover = (state) => {
  state.expandOnHover = false;
};

export const openCloseTheSoundOverlay = (state, payload) => {
  state.showTheSoundOverlay = payload;
};
