export const allChats = (state) => {
  return state.allChats;
};

export const targetChat = (state) => {
  return state.targetChat;
};

export const targetChatCharges = (state) => {
  return state.targetChatCharges;
};

export const chatBundles = (state) => {
  return [
    {
      id: 1,
      name: "Bronze",
      credits: state.targetChatCharges.bronzeCreditAmount,
      price: state.targetChatCharges.bronzePrice,
      presentation:
        "radial-gradient(ellipse farthest-corner at right top, #ffffff 0%, #e1b384 8%, #d7995b 25%, #905923 82.5%, #a46628 100%)",
    },
    {
      id: 2,
      name: "Silver",
      credits: state.targetChatCharges.silverCreditAmount,
      price: state.targetChatCharges.silverPrice,
      presentation:
        "radial-gradient(ellipse farthest-corner at right top, #ffffff 0%, #b3b3b3 8%, #999999 25%, #404040 82.5%, #4d4d4d 100%)",
    },
    {
      id: 3,
      name: "Gold",
      credits: state.targetChatCharges.goldCreditAmount,
      price: state.targetChatCharges.goldPrice,
      presentation:
        "radial-gradient(ellipse farthest-corner at right top, #ffffff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 82.5%, #5d4a1f 100%)",
    },
    {
      id: 4,
      name: "Platinum",
      credits: state.targetChatCharges.platinumCreditAmount,
      price: state.targetChatCharges.platinumPrice,
      presentation:
        "radial-gradient(ellipse farthest-corner at right top, #e5e4e2 0%, #e5e4e2 8%, #e5e4e2 25%, #aaa7a1 82.5%, #5e5b55 100%)",
    },
  ];
};

export const chatSettings = (state) => {
  return [
    {
      id: 1,
      icon: "fas fa-comment",
      iconName: "Chat Name",
      dataValue: state.targetChat?.chatName,
      disabled: false,
      inputType: 1,
    },
    {
      id: 2,
      icon: "fas fa-list-ol",
      iconName: "Number Set",
      dataValue: state.targetChat.magicNumberSetId,
      disabled: false,
      inputType: 2,
    },
    {
      id: 3,
      icon: "fas fa-mobile-alt",
      iconName: "Phone Number",
      dataValue: state.targetChat.shortCode,
      disabled: false,
      inputType: 1,
    },
    {
      id: 4,
      icon: "fas fa-globe",
      iconName: "Website URL",
      dataValue: state.targetChat.websiteUrl,
      disabled: false,
      inputType: 1,
    },
    {
      id: 5,
      icon: "fa-solid fa-user-graduate",
      iconName: "Adult Verification",
      dataValue: state.targetChat.requiresAdultVerification,
      disabled: false,
      inputType: 2,
    },
  ];
};

export const regionAttributes = (state) => {
  return state.regionAttributes;
};

export const regionFlagImage = (state) => (regionId) => {
  const targetRegion = state.regionAttributes.find(
    (region) => region.regionId === regionId
  );
  return targetRegion ? targetRegion.image : null;
};

export const regionTileFlagImage = (state) => (regionId) => {
  const targetRegion = state.regionAttributes.find(
    (region) => region.regionId === regionId
  );
  return targetRegion ? targetRegion.tileImage : null;
};

export const regionColor = (state) => (regionId) => {
  const targetRegion = state.regionAttributes.find(
    (region) => region.regionId === regionId
  );
  return targetRegion ? targetRegion.color : null;
};

export const regionCurrencyIcon = (state) => (regionId) => {
  const targetRegion = state.regionAttributes.find(
    (region) => region.regionId === regionId
  );
  return targetRegion ? targetRegion.currencyIcon : null;
};

export const regionCurrencySymbol = (state) => (regionId) => {
  const targetRegion = state.regionAttributes.find(
    (region) => region.regionId === regionId
  );
  return targetRegion ? targetRegion.currencySymbol : null;
};

export const regionName = (state) => (regionId) => {
  const targetRegion = state.regionAttributes.find(
    (region) => region.regionId === regionId
  );
  return targetRegion ? targetRegion.name : null;
};
