import { getAllAlerts } from "@/api/requestService";
export const fetchAlerts = async (context) => {
  try {
    const response = await getAllAlerts();
    context.commit("setAlertsList", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      { text: "Oops! Couldn't load alerts", error: error.response?.status },
      {
        root: true,
      }
    );
  }
};
