import {
  getTotalConversationDetails,
  findNotesByConversationAndProfileId,
} from "@/api/requestService";
import notificationSound from "@/assets/audio/notificationSound.wav";
let soundPlayed;
export const fetchTotalConversations = async (context) => {
  try {
    const response = await getTotalConversationDetails();
    context.commit("setTotalConversations", response.data);

    if (document.hidden) {
      context.getters.totalConversations.forEach((conversation) => {
        if (!context.getters.seenConversationIds.includes(conversation.id)) {
          if (context.getters.isSoundEnabled && !soundPlayed) {
            const audio = new Audio(notificationSound);
            audio.volume = context.getters.soundVolume / 100;
            audio.play();
            soundPlayed = true;
          }
          context.commit("addConversationIdToSeen", conversation.id);
        }
      });
      soundPlayed = false;
      if (context.getters.availableConversations.length === 0) {
        document.title = document.title.replace(
          /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu,
          ""
        );
      } else {
        document.title = document.title.replace(
          /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu,
          ""
        );
        document.title += " 🟠";
      }
    } else {
      document.title = document.title.replace(
        /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu,
        ""
      );
    }
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load conversations",
        error: error.response?.status,
      },
      {
        root: true,
      }
    );
  }
};
export const fetchNotesByProfile = async (
  context,
  { conversationId, profileId, profileType }
) => {
  try {
    const response = await findNotesByConversationAndProfileId(
      conversationId,
      profileId
    );
    if (profileType === "customer") {
      context.commit("setCustomerNotes", response.data);
    } else if (profileType === "nonCustomer") {
      context.commit("setNonCustomerNotes", response.data);
    }
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load notes",
        error: error.response?.status,
      },
      {
        root: true,
      }
    );
  }
};

export const changeOverlayMessage = (context, message) => {
  context.commit("changeOverlayMessage", message);
};
