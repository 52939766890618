export const setAllChats = (state, payload) => {
  state.allChats = payload;
};

export const setTargetChat = (state, payload) => {
  state.targetChat = payload;
};
export const setTargetChatCharges = (state, payload) => {
  state.targetChatCharges = payload;
};
