export const setRegionRangeIds = (state, payload) => {
  state.regionId = payload.regionId;
  state.rangeId = payload.rangeId;
};

export const setRevenueData = (state, payload) => {
  state.revenueData = payload;
};

export const setTransactionsData = (state, payload) => {
  state.transactions = payload;
};

export const setMessageCountsData = (state, payload) => {
  state.messageCounts = payload;
};

export const setMessageCountType = (state, payload) => {
  state.messageCountType = payload;
};
