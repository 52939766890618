export const allTemplates = (state) => {
  const allTemplatesCopy = [...state.allTemplates];

  return allTemplatesCopy.sort((a, b) => {
    const nameA = a.description.toLowerCase();
    const nameB = b.description.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const getTemplateById = (state) => (id) => {
  return state.allTemplates.find((template) => template.id === id);
};

export const allChipTypes = (state) => {
  return state.allChipTypes;
};

export const getChipTypeById = (state) => (id) => {
  return state.allChipTypes.find((chip) => chip.value === id);
};
