export default {
  allChats: [],
  targetChat: {},
  targetChatCharges: {},
  regionAttributes: [
    {
      regionId: 1,
      name: "Australia",
      image: "https://imchatimages.s3.eu-west-1.amazonaws.com/flags/au.png",
      tileImage: `${require("@/assets/images/tiles/au.png")}`,
      color: "chatAustralia",
      currencyIcon: "fas fa-dollar-sign",
      currencySymbol: "$",
    },
    {
      regionId: 2,
      name: "United Kingdom",
      image: "https://imchatimages.s3.eu-west-1.amazonaws.com/flags/gb.png",
      tileImage: `${require("@/assets/images/tiles/gb.png")}`,
      color: "chatUK",
      currencyIcon: "fas fa-pound-sign",
      currencySymbol: "£",
    },
    {
      regionId: 3,
      name: "Ireland",
      image: "https://imchatimages.s3.eu-west-1.amazonaws.com/flags/ie.png",
      tileImage: `${require("@/assets/images/tiles/ie.png")}`,
      color: "chatIreland",
      currencyIcon: "fas fa-euro-sign",
      currencySymbol: "€",
    },
    {
      regionId: 4,
      name: "New Zealand",
      image: "https://imchatimages.s3.eu-west-1.amazonaws.com/flags/nz.png",
      tileImage: `${require("@/assets/images/tiles/nz.png")}`,
      color: "chatNZ",
      currencyIcon: "fas fa-dollar-sign",
      currencySymbol: "$",
    },
    {
      regionId: 5,
      name: "Canada",
      image: "https://imchatimages.s3.eu-west-1.amazonaws.com/flags/ca.png",
      tileImage: `${require("@/assets/images/tiles/ca.png")}`,
      color: "chatCanada",
      currencyIcon: "fas fa-dollar-sign",
      currencySymbol: "$",
    },
    {
      regionId: 6,
      name: "United States",
      image: "https://imchatimages.s3.eu-west-1.amazonaws.com/flags/us.png",
      tileImage: `${require("@/assets/images/tiles/us.png")}`,
      color: "chatUS",
      currencyIcon: "fas fa-dollar-sign",
      currencySymbol: "$",
    },
  ],
};
