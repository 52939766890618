export const allOperators = (state) => {
  const allSystemOperatorsCopy = [...state.allSystemOperators];

  return allSystemOperatorsCopy.sort((a, b) => {
    const nameA = a.firstName.toLowerCase();
    const nameB = b.firstName.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const activeOperators = (state) => {
  const filteredOperators = state.allSystemOperators.filter(
    (operator) => operator.enabled === true
  );

  return filteredOperators.sort((a, b) => {
    const nameA = a.firstName.toLowerCase();
    const nameB = b.firstName.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const getOperatorById = (state) => (id) => {
  return state.allSystemOperators.find(
    (operator) => operator.id === parseInt(id)
  );
};
