import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import navigation from "./modules/navigation";
import dashboard from "./modules/dashboard";
import operators from "@/store/modules/operators";
import numbers from "@/store/modules/numbers";
import templates from "@/store/modules/templates";
import profiles from "@/store/modules/profiles";
import chats from "@/store/modules/chats";
import queues from "@/store/modules/queues";
import stats from "@/store/modules/stats";
import toast from "@/store/modules/toast";
import alerts from "@/store/modules/alerts";

export default new Vuex.Store({
  modules: {
    dashboard,
    navigation,
    operators,
    numbers,
    templates,
    profiles,
    chats,
    queues,
    stats,
    toast,
    alerts,
  },
});
