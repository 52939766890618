import {
  getTransactionStatsByRegionAndRange,
  getMessageCountStatsByRegionAndRange,
  getRevenueByRegionAndRange,
  getAverageRevenueByRegionAndRange,
} from "@/api/requestService";

export const fetchRevenueData = async (context) => {
  try {
    const responsePrevious = await getRevenueByRegionAndRange(
      context.state.regionId,
      context.state.rangeId
    );
    const responseAverage = await getAverageRevenueByRegionAndRange(
      context.state.regionId,
      context.state.rangeId
    );
    const rangeRevenue = responsePrevious.data[0].revenue.toFixed(2);
    const previousRangeRevenue = responsePrevious.data[1].revenue.toFixed(2);
    const averageRangeRevenue = responseAverage.data.toFixed(2);
    context.commit("setRevenueData", {
      rangeRevenue,
      previousRangeRevenue,
      averageRangeRevenue,
    });
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load revenue data",
        error: error.response?.status,
      },
      {
        root: true,
      }
    );
  }
};

export const fetchTransactionData = async (context) => {
  try {
    const response = await getTransactionStatsByRegionAndRange(
      context.state.regionId,
      context.state.rangeId
    );
    context.commit("setTransactionsData", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load transactions data",
        error: error.response?.status,
      },
      {
        root: true,
      }
    );
  }
};

export const fetchMessageCountsData = async (context) => {
  try {
    const response = await getMessageCountStatsByRegionAndRange(
      context.state.regionId,
      context.state.rangeId
    );
    context.commit("setMessageCountsData", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't load messages count data",
        error: error.response?.status,
      },
      {
        root: true,
      }
    );
  }
};

export const fetchAllDataForTransactionsRoute = async (context) => {
  await context.dispatch("fetchRevenueData");
  await context.dispatch("fetchTransactionData");
  await context.dispatch("fetchMessageCountsData");
};
