import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: localStorage.getItem("darkTheme") === "true",
    themes: {
      light: {
        consolePrimary: "#433f59",
        consoleText: "#433f59",
        consoleTextWhite: "#FEFEFE",
        consoleBackground: "#f6f7fb",
        consoleBackgroundSecondary: "#685f9d",
        consoleWhite: "#FEFEFE",
        consoleGrey: "#d3d3d3",
        consoleRed: "#f54436",
        consoleGreen: "#4baf4f",
        consoleBlue: "#4169E1",
        consolePink: "#FF007F",
        consoleAmber: "#ffb300",
        chatUK: "#1490a4",
        chatAustralia: "#6A8E3F",
        chatIreland: "#E35C6A",
        chatNZ: "#EA9558",
        chatCanada: "#F2D688",
        chatUS: "#2C7F6E",
        developer: "#685f9d",
        admin: "#1490a4",
        supervisor: "#e35c6a",
        operator: "#ea9558",
        payPalColor: "#07378b",
        applePayColor: "#080808",
        googlePayColor: "#5a93f6",
        freeCreditsColor: "#FFD700",
        consoleVenus: "#ea316d",
        consoleMars: "#2196f3",
        consoleOtherGender: "#ECC30B",
      },
      dark: {
        consolePrimary: "#555078",
        consoleText: "#FEFEFE",
        consoleTextWhite: "#FEFEFE",
        consoleBackground: "#161618",
        consoleBackgroundSecondary: "#463E67",
        consoleWhite: "#121212",
        consoleGrey: "#A9A9A9",
        consoleRed: "#B30000",
        consoleGreen: "#007F00",
        consoleBlue: "#1A478C",
        consolePink: "#A6004C",
        consoleAmber: "#9D7E00",
        chatUK: "#0D6B7C",
        chatAustralia: "#596D1D",
        chatIreland: "#A93745",
        chatNZ: "#B46441",
        chatCanada: "#B4A973",
        chatUS: "#2C7F6E",
        developer: "#4D4675",
        admin: "#0D6B7C",
        supervisor: "#9C3947",
        operator: "#A4563D",
        payPalColor: "#07378b",
        applePayColor: "#0070c9",
        googlePayColor: "#5a93f6",
        freeCreditsColor: "#B89E3D",
        consoleVenus: "#ea316d",
        consoleMars: "#2196f3",
        consoleOtherGender: "#ECC30B",
      },
    },
  },
});
