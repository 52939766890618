export default {
  allTemplates: [],
  allChipTypes: [
    { name: "other", color: "blue darken-2", value: 1 },
    { name: "success", color: "green", value: 2 },
    { name: "error", color: "red", value: 3 },
    { name: "verification", color: "deep-purple darken-3", value: 4 },
    { name: "system", color: "teal lighten-3", value: 5 },
    { name: "credit", color: "yellow darken-2", value: 6 },
    { name: "test", color: "pink accent-4", value: 7 },
  ],
};
