<template>
  <v-row class="align-center justify-end">
    <v-col cols="7" class="py-0">
      <v-text-field
        ref="searchInput"
        hide-details
        v-model="searchInput"
        placeholder="Search..."
        background-color="consoleWhite"
        rounded
        color="consolePrimary"
        class="ma-0 pt-0"
        height="36"
        @keydown.enter="searchInput !== '' && searchForProfiles()"
        :append-icon="searchMode ? 'fa-solid fa-circle-xmark' : ''"
        @click:append="handleLeaveSearchMode"
      />
    </v-col>
    <v-col cols="4">
      <v-btn
        @click="
          getAuthorisedUserRole !== 'ROLE_OPERATOR' &&
            (searchToggle = !searchToggle)
        "
        depressed
        rounded
        color="consoleWhite"
        class="mr-2"
      >
        <v-icon small color="consolePrimary">
          {{ searchToggle ? "fas fa-user" : "fas fa-phone" }}
        </v-icon>
      </v-btn>
      <v-btn
        @click="searchForProfiles"
        depressed
        rounded
        color="consoleWhite"
        :disabled="searchInput === ''"
      >
        <v-icon small color="consolePrimary"> fas fa-search </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      searchInput: "",
      searchToggle: true,
      search: {
        disabled: true,
        toggle: true,
        input: "",
        dynamic: {
          icon: "fas fa-user",
          param: "username",
        },
      },
    };
  },
  computed: {
    ...mapGetters("navigation", ["getAuthorisedUserRole"]),
    ...mapGetters("profiles", ["searchMode"]),
    searchType() {
      return this.searchToggle ? "username" : "phoneNumber";
    },
  },
  methods: {
    ...mapActions("profiles", ["setSearchResults", "fetchProfilesByPage"]),
    async searchForProfiles() {
      const params = new URLSearchParams();
      params.append(
        this.searchType,
        this.searchInput.toLowerCase().toLocaleString()
      );
      await this.setSearchResults({
        paginationPage: 0,
        params: params,
      });
    },
    async handleLeaveSearchMode() {
      await this.fetchProfilesByPage(0);
      this.$store.commit("profiles/setSearchMode", false);
      this.$store.commit("profiles/setCurrentPage", 1);
      this.searchInput = "";
    },
  },
};
</script>

<style scoped>
>>> .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding-right: 8px;
}
>>> .v-text-field .v-input__append-inner,
.v-text-field .v-input__prepend-inner {
  margin-top: 6px;
}
</style>
