export default {
  authorisedUser: {},
  authorisedUserRole: "",
  appVersion: "1.7.2",
  expandOnHover: false,
  headerOptions: [
    {
      id: 1,
      title: "My Profile",
      icon: "fas fa-lock",
      locked: true,
      to: "/",
      roles: [
        "ROLE_DEVELOPER",
        "ROLE_ADMIN",
        "ROLE_SUPERVISOR",
        "ROLE_OPERATOR",
      ],
    },
    {
      id: 4,
      title: "Preferences",
      icon: "fas fa-lock",
      locked: true,
      to: "/",
      roles: [
        "ROLE_DEVELOPER",
        "ROLE_ADMIN",
        "ROLE_SUPERVISOR",
        "ROLE_OPERATOR",
      ],
    },
    {
      id: 3,
      title: "Alerts",
      icon: "fas fa-bullhorn",
      locked: false,
      to: "/alerts",
      roles: ["ROLE_DEVELOPER"],
    },
    {
      id: 2,
      title: "Templates",
      icon: "fas fa-th-list",
      locked: false,
      to: "/templates",
      roles: ["ROLE_DEVELOPER", "ROLE_ADMIN"],
    },
    {
      id: 5,
      title: "Numbers",
      icon: "fas fa-th-list",
      locked: false,
      to: "/numbers",
      roles: ["ROLE_DEVELOPER", "ROLE_ADMIN"],
    },
  ],
  navigationOptions: [
    {
      id: 1,
      title: "Dashboard",
      icon: "space_dashboard",
      to: "/dashboard",
      roles: [
        "ROLE_DEVELOPER",
        "ROLE_ADMIN",
        "ROLE_SUPERVISOR",
        "ROLE_OPERATOR",
      ],
    },
    {
      id: 2,
      title: "Queues",
      icon: "fas fa-stream",
      to: "/queues",
      roles: [
        "ROLE_DEVELOPER",
        "ROLE_ADMIN",
        "ROLE_SUPERVISOR",
        "ROLE_OPERATOR",
      ],
    },
    {
      id: 3,
      title: "Profiles",
      icon: "fas fa-users",
      to: "/profiles",
      roles: [
        "ROLE_DEVELOPER",
        "ROLE_ADMIN",
        "ROLE_SUPERVISOR",
        "ROLE_OPERATOR",
      ],
    },
    {
      id: 6,
      title: "Stats",
      icon: "fas fa-chart-area",
      to: "/stats",
      roles: ["ROLE_DEVELOPER", "ROLE_ADMIN", "ROLE_SUPERVISOR"],
    },
    {
      id: 4,
      title: "Chats",
      icon: "fas fa-comments",
      to: "/chats",
      roles: ["ROLE_DEVELOPER", "ROLE_ADMIN", "ROLE_SUPERVISOR"],
    },
    {
      id: 5,
      title: "Operators",
      icon: "fas fa-headset",
      to: "/operators",
      roles: ["ROLE_DEVELOPER", "ROLE_ADMIN"],
    },
  ],
  showTheSoundOverlay: false,
};
