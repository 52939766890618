export default {
  regionId: 2,
  rangeId: 1,
  rangeOptions: [
    { title: "Today", value: 1 },
    { title: "This Month", value: 3 },
  ],
  revenueData: {},
  transactions: [],
  messageCounts: {},
  messageCountType: 1,
};
