export const currentPage = (state) => {
  return state.currentPage;
};
export const profilesByPage = (state) => {
  return state.profilesByPage;
};

export const totalPages = (state) => {
  return state.totalPages;
};

export const getProfileById = (state) => (id) => {
  return state.profilesByPage.find((profile) => profile.id === parseInt(id));
};

export const searchMode = (state) => {
  return state.searchMode;
};

export const searchParams = (state) => {
  return state.searchParams;
};
export const genderList = (state) => {
  return state.genderList;
};
export const orientationList = (state) => {
  return state.orientationList;
};

export const targetGender = (state) => (genderValue) => {
  return state.genderList.find((gender) => gender.value === genderValue);
};

export const targetOrientation = (state) => (orientationValue) => {
  return state.orientationList.find(
    (orientation) => orientation.value === orientationValue
  );
};
