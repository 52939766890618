import {
  getMagicNumberSets,
  getMagicNumbersBySetId,
} from "@/api/requestService";
export const fetchAllNumberSets = async (context) => {
  try {
    const response = await getMagicNumberSets();
    context.commit("fetchAllNumberSets", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't retrieve number sets",
        error: error.response?.status,
      },
      { root: true }
    );
  }
};
export const fetchTargetNumbers = async (context, id) => {
  try {
    const response = await getMagicNumbersBySetId(id);
    context.commit("fetchTargetNumbers", response.data);
  } catch (error) {
    await context.dispatch(
      "toast/enableErrorSnackbar",
      {
        text: "Oops! Couldn't retrieve the requested numbers",
        error: error.response?.status,
      },
      { root: true }
    );
  }
};
